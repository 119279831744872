.process-stepper {
    width: 90%;
    padding: 12.5%;
    background-color: lighten($color-theme, 7);
    color: #fff !important;
    border-radius: 8px;
}

.stepper-col {
    //border-right: 0.5px solid grey;
}