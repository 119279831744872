/**
 * /*
 * Portfolio
 *
 * @format
 */

.mi-portfolio {
    transition: $transition;
    visibility: hidden;
    opacity: 0;
    &-gallery {
        display: flex;
        justify-content: center;
        padding: 15px;
        border-radius: 7px;
        background: #ADA996;
        /* fallback for old browsers */
        background: -webkit-linear-gradient(to top, #EAEAEA, #DBDBDB, #F2F2F2, #ADA996);
        /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to top, #EAEAEA, #DBDBDB, #F2F2F2, #ADA996);
        /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        box-shadow: 2px 2px #ff4600;
    }
    &-image {
        display: block;
        position: relative;
        &::before {
            content: "";
            position: absolute;
            left: 15px;
            top: 15px;
            height: calc(100% - 30px);
            width: calc(100% - 30px);
            background: rgba(#ffffff, 0.9);
            transition: $transition;
            transform: scaleX(0);
            transform-origin: 0;
        }
        img {
            width: 100%;
            max-height: 200px;
            object-fit: contain;
        }
        ul {
            margin-bottom: 0;
            padding-left: 0;
            position: absolute;
            left: 0;
            top: 50%;
            text-align: center;
            width: 100%;
            transform: translateY(-50%);
            visibility: hidden;
            opacity: 0;
            margin-top: 30px;
            transition: all 0.4s ease-in-out 0.2s;
            li {
                list-style: none;
                display: inline-block;
                margin: 0 7px;
                a,
                button {
                    display: inline-block;
                    height: 45px;
                    width: 45px;
                    padding: 5px 0;
                    vertical-align: middle;
                    text-align: center;
                    line-height: 1;
                    background: $color-body;
                    color: #ffffff;
                    border: 1px solid $color-body;
                    border-radius: 100px;
                    outline: none;
                    font-size: 1.25rem;
                    transition: $transition;
                    svg {
                        height: 100%;
                    }
                    &:hover {
                        background: $color-theme;
                        border-color: $color-theme;
                    }
                }
            }
        }
    }
    h5 {
        color: $color-heading;
        margin-top: 15px;
        margin-bottom: 0;
        font-weight: 700;
        a {
            color: #ffffff;
            transition: $transition;
            &:hover {
                color: $color-theme;
            }
        }
    }
    h6 {
        color: $color-body;
        margin-bottom: 0;
    }
    &:hover &-image {
        &::before {
            transform: scaleX(1);
        }
        ul {
            visibility: visible;
            opacity: 1;
            margin-top: 0;
        }
    }
    &.mi-portfolio-visible {
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        visibility: visible;
        opacity: 1;
    }
}