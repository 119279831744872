/**
 * Form Styles
 */

.mi-form {
    &-field {
        margin-top: 30px;
        position: relative;
        label {
            position: absolute;
            left: 15px;
            top: -13px;
            background: $color-bg-body;
            transition: $transition;
            pointer-events: none;
            padding: 0 10px;
            font-size: 0.94rem;
        }
        input,
        textarea {
            border: 1px solid $color-border;
            font-size: 1rem;
        }
    }
}