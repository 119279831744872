/*
@font-face {
    font-family: 'GothamRoundedBold';
    src: local('GothamRoundedBold'), url(./fonts/gotham_rounded/GothamRoundedBold.otf) format('truetype');
}

@font-face {
    font-family: 'GothamRoundedMedium';
    src: local('GothamRoundedMedium'), url(./fonts/gotham_rounded/GothamRoundedMedium.ttf) format('truetype');
}

@font-face {
    font-family: 'GothamRoundedBook';
    src: local('GothamRoundedBook'), url(./fonts/gotham_rounded/GothamRoundedBook.otf) format('truetype');
}

@font-face {
    font-family: 'GothamRoundedLight';
    src: local('GothamRoundedLight'), url(./fonts/gotham_rounded/GothamRoundedLight.otf) format('truetype');
}
*/

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}