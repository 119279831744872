/**
 * Service
 */

.mi-service {
    border: 1px solid $color-border;
    border-top: 5px solid $color-border;
    padding: 30px;
    background: lighten($color-bg-body, 5);
    transition: $transition;
    &-wrapper {
        margin-bottom: 45px;
    }
    &-wrapper {
        //width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 6px;
        margin-bottom: 20px;
    }
    &-icon {
        //margin-bottom: 20px;
        margin: 15px;
        display: flex;
        //display: inline-block;
        //color: $color-theme;
        color: #fff !important;
        //font-size: 4rem;
        .material-icons {
            font-size: 3rem;
        }
    }
    h6 {
        font-weight: 600;
        position: relative;
        padding-bottom: 15px;
        margin-bottom: 15px;
        color: #fff;
        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: auto;
            bottom: 0;
            height: 2px;
            width: 50px;
            background: $color-border;
        }
    }
    p {
        margin-bottom: 0;
    }
    &:hover {
        border-top-color: $color-theme;
    }
    @media #{$layout-laptop} {
        padding: 20px;
    }
    @media #{$layout-mobile-sm} {
        padding: 20px;
    }
}

.mi-tech {
    &-row {
        background-color: #fff;
        border-radius: 8px;
    }
}